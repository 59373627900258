<template>
  <div class='icon-price'>
    <svg-icon size="size16x16" name="icoCoin" />
    <span>{{price}}</span>
  </div>
</template>

<script>
export default {
  name: 'IconPrice',
  props: {
    price: {
      type: Number,
      required: true
    }
  },
}
</script>

<style scoped>
.icon-price {
  background: #141723;
  border-radius: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #D8D8D8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 7px;
}
.icon-price svg {
  margin: 0 !important;
  margin-right: 5px !important;
}
</style>
