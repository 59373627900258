<template>
  <div class="venue-component" :class="classes" @click="select" ref="element">

    <div class="image venue-component__image">
      <img :src="image" :alt="name">
      <div class='venue-component__icon-bottom'>
        <IconFree v-if='price===0'/>
        <IconPrice v-else :price='price'/>
      </div>
      <div hidden class='venue-component__icon-top'>
        <PremiumIcon/>
      </div>
    </div>

    <span class="name"> {{ name }} </span>

    <p class="description"> {{ description }} </p>
  </div>
</template>

<script>

import IconFree from '@/components/IconFree/IconFree'
import IconPrice from '@/components/IconPrice/IconPrice'
import PremiumIcon from '@/components/PremiumIcon/PremiumIcon'

import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Venue',
  mixins: [
    newcompetition
  ],
  components: {IconFree, IconPrice, PremiumIcon},
  props: {
    venue: {
      type: Object,
      required: true
    },
    current: {
      type: Object,
      default: null
    }
  },
  computed: {
    classes () {
      const { selected } = this

      return { selected }
    },
    id () {
      return this.venue.id
    },
    name () {
      return this.venue.name
    },
    image () {
      return this.venue.image_url ? this.venue.image_url : require('@/assets/images/pool.png')
    },
    price () {
      return this.venue.price
    },
    selected () {
      return this.current?.id === this.id
    },
    description () {
      return this.venue.description
    }
  },
  mounted () {
    if (this.selected) {
      this.scroll()
    }
  },
  methods: {
    select () {
      this.$emit('update:current', this.venue)

      this.scroll()
    },
    scroll () {
      this.$refs.element.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }
}
</script>

<style scoped>
.venue-component {
  position: relative;
}
.venue-component__icon-bottom {
  position: absolute;
  bottom: 6px;
  left: 8px;
}
.venue-component__icon-top {
  position: absolute;
  top: 6px;
  right: 8px;
}
.venue-component__image {
  position: relative;
}
</style>
