<template>
  <div class='icon-free'>Free</div>
</template>

<script>

export default {
  name: 'IconFree'
}
</script>

<style scoped>
.icon-free {
  width: 32px;
  height: 18px;
  background: #4285F4;
  border-radius: 20px;
  font-size: 10px;
  line-height: 140%;
  color: #F6F7F9;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
</style>
